import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useCache } from '../../contexts/CacheContext'
import './styles.scss'

import LoadingBars from '../spinner/horizontalLoader'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const extensionMap = {
  '.txt': {
    name: 'Text File'
  },
  '.xml': {
    name: 'XML File'
  },
  '.md': {
    name: 'Markdown',
    renderable: true
  },
  '.markdown': {
    name: 'Markdown',
    renderable: true
  },
  '.yaml': {
    name: 'YAML File'
  },
  '.json': {
    name: 'JSON File'
  },
  '.tex': {
    name: 'LaTeX File',
    renderable: true
  }
}

const TextViewer = ({ url, ext=null }) => {
  const { fetchData, getCacheState } = useCache()
  const { isLoading, data, error } = getCacheState(url)
  const [ willRender, setWillRender ] = useState(false)
  const [ extensionDetails, setExtensionDetails ] = useState({})

  useEffect(() => {
    fetchData(url)
  }, [url, fetchData])

  useEffect(() => {
    let extDetails = extensionMap[ext] || null
    setExtensionDetails(extDetails)
    // default toggle button to preview (rendered) view
    if(extDetails?.renderable === true) {
      setWillRender(true)
    }
  }, [data, ext])
  
  if(!extensionDetails) return null
  let content = null
  if(data) {
    if(extensionDetails?.name === 'Markdown' && willRender) {
      content = (
        <div className='sv3-prvw-basic-txt'>
          <Markdown remarkPlugins={[remarkGfm]}>
            {data}
          </Markdown>
        </div>
      )
    } else {
      content = (
        <div className='sv3-prvw-basic-txt sv3-prvw-txt-wrapper'>
          {data}
        </div>
      )
    }
  } else {
    return null
  }
  let renderToggleView = null
  if(data && extensionDetails?.renderable === true) {
    renderToggleView = (
      <div className='button-container'>
        <button className={`prvw-toggle${willRender ? ' selected' : ''}`}
          onClick={() => setWillRender(true)}
        >Preview</button>
        <button className={`prvw-toggle${willRender ? '' : ' selected'}`}
          onClick={() => setWillRender(false)}
        >Code</button>
      </div>
    )
  }

  return (
    <>
      <Helmet><link href="https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap" rel="stylesheet"/></Helmet>
      <div className='sv3-prvw-title'>
        <h2>
          { extensionDetails.name } Preview { isLoading && <>&nbsp;&nbsp;<LoadingBars/></> }
        </h2>
        {renderToggleView}
      </div>
      {content}
    </>
  )
}

export default TextViewer