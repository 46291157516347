import React from 'react'
import './styles.scss'
import TableViewer from './tableviewer'
import HTMLViewer from './htmlviewer'
import TextViewer from './textviewer'

const Previewer = ({ label=null, href=null }) => {
  if(!href) return <a href={href}>{label}</a>
  const ext = getFileExtension(href)
  console.log(href, ext)
  if(ext === '.csv') {
    return <TableViewer url={href}/>
  } else if(ext === '.html') {
    return <HTMLViewer url={href}/>
  } else if(['.txt', '.md', '.markdown', '.yaml', '.tex'].includes(ext)) {
    return <TextViewer url={href} ext={ext}/>
  } else return null
}

const getFileExtension = (fileUrl) => {
  try {
    const parsedUrl = new URL(fileUrl)
    const pathname = parsedUrl.pathname
    return pathname.substring(pathname.lastIndexOf('.')) || null
  } catch (error) {
    console.error("Invalid URL:", error.message)
    return null
  }
}

export default Previewer