import { useEffect } from "react"

const formatBytes = (bytes, decimals = 1) => {
  if (!+bytes) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const formatUTC = (value, onlyTime=true) => {
  var time = new Date(value).toLocaleTimeString([], {hour: 'numeric', minute: 'numeric'})
  if(onlyTime) {
    return time
  } else {
    return new Date(value).toLocaleDateString([], {day: '2-digit', month: '2-digit', year: 'numeric'}) + " " + time
  }
}

const simpleFormatUTC = (value) => {
  let tempFormat = new Date(value).toLocaleDateString([], {day: '2-digit', month: '2-digit', year: '2-digit'})
  let today = new Date().toLocaleDateString([], {day: '2-digit', month: '2-digit', year: '2-digit'})
  if(tempFormat === today) return new Date(value).toLocaleTimeString([], {hour: 'numeric', minute: 'numeric'})
  else return tempFormat
}

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (textAreaRef, value) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = scrollHeight + "px";
    }
  }, [textAreaRef, value]);
}

const getRandomString = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const msgReadHelper = (msgInstance, selfID) => {
  let unreadMarker = false
  let lastRead = null
  let otherParty = null

  if(selfID === msgInstance?.threadID?.buyerID) {
    lastRead = msgInstance?.threadID?.buyerLastRead || '2022-11-01T00:00:00.000Z'
    otherParty = msgInstance?.threadID?.sellerName
  } else {
    lastRead = msgInstance?.threadID?.sellerLastRead || '2022-11-01T00:00:00.000Z'
    otherParty = msgInstance?.threadID?.buyerName
  }
  const lastMsg = msgInstance?.threadID?.lastMsg
  const lastReadTime = new Date(lastRead).getTime()
  const lastMsgTime = new Date(lastMsg?.createdAt).getTime()
  if(selfID !== lastMsg?.sender) {
    unreadMarker = lastReadTime < lastMsgTime
  }
  return {unreadMarker, otherParty, lastMsg, lastRead}
}

const parseCodeNText = (textWithCode) => {
  var codePattern = /```([\s\S]*?)```/g

  // Use the regular expression exec method to extract all code snippets
  var codeSnippets = []
  var textParts = []
  var match

  while ((match = codePattern.exec(textWithCode)) !== null) {
    var c = match[1]
    if(c.startsWith('python\n')) {
      codeSnippets.push(c.replace('python\n', ''))
    } else {
      textParts.push(c)
    }
    textParts.push(textWithCode.substring(0, match.index))
    textWithCode = textWithCode.substring(match.index + match[0].length)
  }

  // Add the remaining text (after the last code block) to textParts
  textParts.push(textWithCode)
  return {text: textParts, code: codeSnippets}
}

const stringCleanup = (inputString) => {
  // Replace special characters, spaces, dots, and dashes with an empty string
  const regex = /[^\w\s]|-/g
  return inputString.replace(regex, '')
}

const copyToClipboard = (text, callback) => {
  if (!navigator.clipboard) {
    console.error('Clipboard API not supported')
    return
  }

  navigator.clipboard.writeText(text)
    .then(() => {
      console.log('Text copied to clipboard')
      if (typeof callback === 'function') {
        callback()
      }
    })
    .catch(err => {
      console.error('Failed to copy text: ', err)
    })
}

const extractDomainFromEmail = (email) => {
  const regex = /^[^@]+@([^@]+\.[^@]+)$/
  const match = email.match(regex)
  return match ? match[1] : null
}

/**
 * Detects the operating system of the host machine based on the browser's user agent.
 *
 * @returns {String} - The name of the operating system: "Windows", "MacOS", "Linux", 
 * "Chrome OS", "Android", "iOS", or "Unknown".
 */
const detectOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/Win/i.test(userAgent)) {
    return "Windows"
  } else if (/CrOS/i.test(userAgent)) {
    return "Chrome OS"
  } else if (/Mac/i.test(userAgent)) {
    // Check for iOS devices that pretend to be MacOS
    if ("ontouchend" in document) {
      return "iOS (iPad)"
    }
    return "MacOS"
  } else if (/Linux/i.test(userAgent)) {
    return "Linux"
  } else if (/Android/i.test(userAgent)) {
    return "Android"
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "iOS"
  } else {
    return "Unknown"
  }
}

/**
 * Extracts the creation timestamp from a MongoDB ObjectId and returns it as a Date object.
 * 
 * @param {*} objectID - The MongoDB ObjectId in string format.
 * @returns {Date} - A Date object representing the creation time of the ObjectId.
 */
const getDateFromObjectID = (objectID) => {
  const timestamp = parseInt(objectID.substring(0, 8), 16) * 1000
  return new Date(timestamp)
}

export {formatBytes, capitalizeFirstLetter, formatUTC, useAutosizeTextArea, getRandomString, simpleFormatUTC, msgReadHelper, parseCodeNText, stringCleanup, copyToClipboard, extractDomainFromEmail, detectOS, getDateFromObjectID}