import React, { useEffect, useState, useRef, memo, useCallback, useMemo } from 'react'

// Auto scales full frame
const AutoScaleIframeV1 = ({ srcDoc, maxWidth=800, maxHeight=1200 }) => {
  const iframeRef = useRef(null)
  const containerRef = useRef(null)
  const [scale, setScale] = useState(1)
  const [dimensions, setDimensions] = useState({ width: 800, height: 600 })

  const resizeIframe = () => {
    if (!iframeRef.current || !containerRef.current) return

    const iframe = iframeRef.current
    const container = containerRef.current
    const doc = iframe.contentDocument || iframe.contentWindow.document

    if (doc.body) {
      // Get the full content size
      const contentWidth = Math.max(
        doc.documentElement.scrollWidth,
        doc.body.scrollWidth
      )
      const contentHeight = Math.max(
        doc.documentElement.scrollHeight,
        doc.body.scrollHeight
      )
      
      // Get container dimensions
      const containerWidth = container.clientWidth
      
      // Calculate scale factor to fit width
      const newScale = Math.min(1, containerWidth / contentWidth)
      
      setScale(newScale)
      setDimensions({
        width: contentWidth,
        height: contentHeight
      })
    }
  }

  useEffect(() => {
    const iframe = iframeRef.current
    
    if (iframe) {
      // Add load event listener
      iframe.addEventListener('load', resizeIframe)
      
      // Create ResizeObserver to watch container size changes
      const resizeObserver = new ResizeObserver(resizeIframe)
      if (containerRef.current) {
        resizeObserver.observe(containerRef.current)
      }

      // Create MutationObserver to watch for content changes
      const mutationObserver = new MutationObserver(resizeIframe)
      
      iframe.addEventListener('load', () => {
        const doc = iframe.contentDocument || iframe.contentWindow.document
        mutationObserver.observe(doc.body, {
          attributes: true,
          childList: true,
          subtree: true
        })
      })

      // Cleanup function
      return () => {
        iframe.removeEventListener('load', resizeIframe)
        resizeObserver.disconnect()
        mutationObserver.disconnect()
      }
    }
  }, [])

  return (
    <div 
      ref={containerRef} 
      className="w-full overflow-hidden"
      style={{
        height: dimensions.height * scale,
      }}
    >
      <iframe
        ref={iframeRef}
        srcDoc={srcDoc}
        className="border-none"
        style={{
          width: dimensions.width,
          height: dimensions.height,
          maxWidth: maxWidth,
          maxHeight: maxHeight,
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
          backgroundColor: 'white',
          borderRadius: '7.5px'
        }}
      />
    </div>
  )
}

// Auto scales but respects maxWidth
const AutoScaleIframeV2 = ({ srcDoc, maxWidth = '100%', maxHeight = 1200 }) => {
  const iframeRef = useRef(null)
  const containerRef = useRef(null)
  const [scale, setScale] = useState(1)
  const [dimensions, setDimensions] = useState({ width: 800, height: 600 })

  const getMaxWidthInPixels = () => {
    if (!containerRef.current) return window.innerWidth

    if (typeof maxWidth === 'number') {
      return maxWidth
    }

    if (typeof maxWidth === 'string') {
      if (maxWidth.endsWith('%')) {
        const percentage = parseFloat(maxWidth) / 100
        return containerRef.current.clientWidth * percentage
      }
      if (maxWidth.endsWith('px')) {
        return parseFloat(maxWidth)
      }
      // Handle other units if needed (em, rem, etc.)
      return containerRef.current.clientWidth
    }

    return containerRef.current.clientWidth
  }

  const resizeIframe = () => {
    if (!iframeRef.current || !containerRef.current) return

    const iframe = iframeRef.current
    const container = containerRef.current
    const doc = iframe.contentDocument || iframe.contentWindow.document

    if (doc.body) {
      // Get the full content size
      const contentWidth = Math.max(
        doc.documentElement.scrollWidth,
        doc.body.scrollWidth
      )
      const contentHeight = Math.max(
        doc.documentElement.scrollHeight,
        doc.body.scrollHeight
      )

      // Get container dimensions and maxWidth in pixels
      const containerWidth = container.clientWidth
      const maxWidthInPixels = getMaxWidthInPixels()

      // Calculate scale factor to fit width while respecting maxWidth
      const effectiveMaxWidth = Math.min(maxWidthInPixels, containerWidth)
      const newScale = Math.min(1, effectiveMaxWidth / contentWidth)

      // Update state
      setScale(newScale)
      setDimensions({
        width: contentWidth,
        height: contentHeight
      })
    }
  }

  useEffect(() => {
    const iframe = iframeRef.current
    
    if (iframe) {
      // Add load event listener
      iframe.addEventListener('load', resizeIframe)
      
      // Create ResizeObserver to watch container size changes
      const resizeObserver = new ResizeObserver(resizeIframe)
      if (containerRef.current) {
        resizeObserver.observe(containerRef.current)
      }

      // Create MutationObserver to watch for content changes
      const mutationObserver = new MutationObserver(resizeIframe)
      
      iframe.addEventListener('load', () => {
        const doc = iframe.contentDocument || iframe.contentWindow.document
        mutationObserver.observe(doc.body, {
          attributes: true,
          childList: true,
          subtree: true
        })
      })

      // Add window resize listener for responsive maxWidth
      window.addEventListener('resize', resizeIframe)

      // Cleanup function
      return () => {
        iframe.removeEventListener('load', resizeIframe)
        window.removeEventListener('resize', resizeIframe)
        resizeObserver.disconnect()
        mutationObserver.disconnect()
      }
    }
  }, [maxWidth])    // Added maxWidth to dependencies

  return (
    <div 
      ref={containerRef} 
      className="w-full relative"
      style={{
        maxHeight,
        overflow: 'auto'
      }}
    >
      <div
        className="w-full"
        style={{
          height: Math.min(dimensions.height * scale, maxHeight),
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <iframe
          ref={iframeRef}
          srcDoc={srcDoc}
          className="border-none absolute top-0 left-0"
          style={{
            width: dimensions.width,
            height: dimensions.height,
            transform: `scale(${scale})`,
            transformOrigin: 'top left',
            backgroundColor: 'transparent',
            borderRadius: '8px'
          }}
        />
      </div>
    </div>
  )
}

const AutoScaleIframeV3 = ({ srcDoc, maxWidth = '100%', maxHeight = 1200 }) => {
  const iframeRef = useRef(null)
  const containerRef = useRef(null)
  const [scale, setScale] = useState(1)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 }) // Initialize with 0

  const getMaxWidthInPixels = () => {
    if (!containerRef.current) return window.innerWidth

    if (typeof maxWidth === 'number') {
      return maxWidth
    }

    if (typeof maxWidth === 'string') {
      if (maxWidth.endsWith('%')) {
        const percentage = parseFloat(maxWidth) / 100
        return containerRef.current.clientWidth * percentage
      }
      if (maxWidth.endsWith('px')) {
        return parseFloat(maxWidth)
      }
      return containerRef.current.clientWidth
    }

    return containerRef.current.clientWidth
  }

  const resizeIframe = () => {
    if (!iframeRef.current || !containerRef.current) return

    const iframe = iframeRef.current
    const container = containerRef.current
    const doc = iframe.contentDocument || iframe.contentWindow.document

    if (doc.body) {
      // Get the full content size
      const contentWidth = Math.max(
        doc.documentElement.scrollWidth,
        doc.body.scrollWidth
      )
      const contentHeight = Math.max(
        doc.documentElement.scrollHeight,
        doc.body.scrollHeight
      )

      // Get container dimensions and maxWidth in pixels
      const containerWidth = container.clientWidth
      const maxWidthInPixels = getMaxWidthInPixels()

      // Calculate scale factor to fit width while respecting maxWidth
      const effectiveMaxWidth = Math.min(maxWidthInPixels, containerWidth)
      const newScale = Math.min(1, effectiveMaxWidth / contentWidth)

      // Update state only if dimensions have changed
      if (contentWidth !== dimensions.width || contentHeight !== dimensions.height) {
        setScale(newScale)
        setDimensions({
          width: contentWidth,
          height: contentHeight
        })
      }
    }
  }

  useEffect(() => {
    const iframe = iframeRef.current
    
    if (iframe) {
      // Add load event listener
      iframe.addEventListener('load', resizeIframe)
      
      // Create ResizeObserver to watch container size changes
      const resizeObserver = new ResizeObserver(resizeIframe)
      if (containerRef.current) {
        resizeObserver.observe(containerRef.current)
      }

      // Create MutationObserver to watch for content changes
      const mutationObserver = new MutationObserver(resizeIframe)
      
      iframe.addEventListener('load', () => {
        const doc = iframe.contentDocument || iframe.contentWindow.document
        mutationObserver.observe(doc.body, {
          attributes: true,
          childList: true,
          subtree: true
        })
      })

      // Add window resize listener for responsive maxWidth
      window.addEventListener('resize', resizeIframe)

      // Cleanup function
      return () => {
        iframe.removeEventListener('load', resizeIframe)
        window.removeEventListener('resize', resizeIframe)
        resizeObserver.disconnect()
        mutationObserver.disconnect()
      }
    }
  }, [maxWidth])

  const scaledHeight = dimensions.height * scale
  const finalHeight = Math.min(scaledHeight, maxHeight)

  return (
    <div 
      ref={containerRef} 
      className="w-full relative"
      style={{
        maxHeight,
        overflow: 'auto'
      }}
    >
      <div
        className="w-full"
        style={{
          height: finalHeight || 'auto', // Use auto height when dimensions not yet set
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <iframe
          ref={iframeRef}
          srcDoc={srcDoc}
          className="border-none absolute top-0 left-0"
          style={{
            width: dimensions.width || '100%', // Use 100% width when dimensions not yet set
            height: dimensions.height || '100%', // Use 100% height when dimensions not yet set
            transform: `scale(${scale})`,
            transformOrigin: 'top left',
            backgroundColor: 'transparent',
            borderRadius: '8px'
          }}
        />
      </div>
    </div>
  )
}

export { AutoScaleIframeV1, AutoScaleIframeV2, AutoScaleIframeV3 }