import React, {useContext, useEffect, useMemo, useState} from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
// import './sandbox-chat.scss'
import './sandbox-chat-v2.scss'
import SandBoxContext from '../../../contexts/SandBoxContext'
import { FlowButton } from '../../../components/flow-ui/flow-blocks'
import { quotationCleaner, urlFormatter } from '../../../utilities/input-formatter'
import reactStringReplace from 'react-string-replace'
import Previewer from '../../../components/previewer/previewer'
// import IntroV2 from '../../../components/intro-v2/intro'

const SandBoxChat = () => {
  const { sandboxState } = useContext(SandBoxContext)
  const { prompts, testPrompts } = sandboxState
  var content = null
  var intro = null
  var padding = null
  if(sandboxState?.metadata?.promptTesting?.enabled === true) {
    content = [...testPrompts]
  } else {
    content = [...prompts]
  }
  let numOfContents = content?.length ?? 0
  if(numOfContents > 0) {
    padding = <div style={{flexGrow: 1}}></div>
    content.reverse()
    content = content.map((c, i) => <SandBoxConvoBlock key={(numOfContents - i)} role={c.role} content={c.content} reference={c?.reference}/>)
  } else {
    intro = <BasicIntro/>
    // intro = <IntroV2/>
  }
  return (
    <div className='sndbx-chat-container' style={{paddingLeft: '10px'}}>
      <div className='sndbx-content-body' style={{display: 'flex', flexDirection: 'column-reverse'}}>
        {intro}
        {content}
        {padding}
      </div>
    </div>
  )
}

export default SandBoxChat

const BasicIntro = () => {
  const {sandboxState} = useContext(SandBoxContext)
  var testMode = null
  if(sandboxState?.metadata?.promptTesting?.enabled === true) {
    testMode = <><FlowButton style={{marginLeft: '10px', marginBottom: '3px', filter: 'brightness(100%)', fontWeight: 700, color: 'orange'}}>Test Mode</FlowButton></>
  }
  return (
    <div className='sndbx-intro'>
      <div style={{paddingLeft: '10px'}}>
        <div style={{display: 'flex', alignItems: 'flex-end'}}>
          I am Nelima{testMode}
        </div>
        <span style={{fontSize: '14px', fontWeight: 500}}>Community driven Large Action Model.</span>
      </div>
    </div>
  )
}



const SandBoxConvoBlock = ({role, content, reference}) => {
  if(reference) {
    return <>
      <SandBoxAssistantBlock content={content}/>
      <SandBoxUserBlockReply content={reference}/>
      <div style={{marginTop: '15px'}}></div>
    </>
  }
  if(role === 'assistant') {
    return <SandBoxAssistantBlock content={content}/>
  }
  else if(role === 'user') return <SandBoxUserBlock content={content}/>
}

const SandBoxUserBlock = ({content=''}) => {
  let formattedContent = reactStringReplace(content, /(https?:\/\/[^\s]+)/g, urlFormatter)
  return (
    <div className='sndbx-convo-base sndbx-convo-user'>
      {/* <div style={{minWidth: '16px', marginRight: '8px', fontSize: '16px', lineHeight: 1}}>
        <i className="fas fa-quote-left"></i>
      </div> */}
      <div className='quote-blk'><i className="fas fa-quote-left"></i></div>
      {formattedContent}
    </div>
  )
}

const SandBoxUserBlockReply = ({content=''}) => {
  let formattedContent = reactStringReplace(content, /(https?:\/\/[^\s]+)/g, urlFormatter)
  return (
    <div className='sndbx-convo-base sndbx-convo-user'>
      {/* <div className='sndbx-convo-rply'><i className="fas fa-reply"></i>&nbspNelima replied to You</div> */}
      <div className='sndbx-convo-rply'>
        <div className='rply-smbl'></div>
        &nbsp;&nbsp;Nelima replied to You
      </div>
      <div className='quote-blk'><i className="fas fa-clock" style={{marginTop: '5px', color: 'orange'}}></i></div>
      {formattedContent}
    </div>
  )
}

const SandBoxAssistantBlockOld = ({content='', isEdited=false}) => {
  const [isCopied, setIsCopied] = useState(false)
  
  useEffect(() => {
    const links = document.querySelectorAll('.markdown-wrapper a')
    links.forEach(link => {
      link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noopener noreferrer')
    })
  }, [])

  const handleCopy = () => {
    if(!isCopied) {
      navigator.clipboard.writeText(content).then(() => {
        setIsCopied(true)
        setTimeout(() => {
          if(setIsCopied) setIsCopied(false)
        }, 2000)
      }).catch(err => {
        console.log('Error copying to clipboard')
      })
    }
  }
  
  const markdownComponents = useMemo(() => ({
    p: ({ node, children }) => {
      console.log(node)
      // Extract links from the paragraph
      const links = node.children.filter(
        child => child.type === "element" && child.tagName === "a"
      )

      // Check if any of the links are from cloud.sellagen.com
      const fileLinks = links.filter(
        link => link.properties?.href?.startsWith("https://cloud.sellagen.com/")
      )

      console.log('file links for viewer parsing', fileLinks)

      if (fileLinks.length > 0) {
        return (
          <>
            <p>{children}</p>
            {fileLinks.map((link) => (
              <Previewer 
                key={`viewer-${link.properties.href}`}
                label={link.children[0]?.value}
                href={link.properties.href}
              />
            ))}
          </>
        )
      }

      return <p>{children}</p>
    }
  }), [])

  return (
    <div className='sndbx-convo-base sndbx-convo-assistant'>
      <div className='convo-control'>
        {isCopied ? 
          <i className="far fa-check ctrl-btn"></i>
          :
          <i className="far fa-clipboard ctrl-btn"
            onClick={handleCopy}
          ></i>
        }
      </div>
      <Markdown 
        className='markdown-wrapper'
        remarkPlugins={[remarkGfm]}
        components={markdownComponents}
      >
        {quotationCleaner(content)}
      </Markdown>
    </div>
  )
}

const SandBoxAssistantBlock = ({content='', isEdited=false}) => {
  const [isCopied, setIsCopied] = useState(false)
  const [fileLinks, setFileLinks] = useState([])
  
  useEffect(() => {
    const links = document.querySelectorAll('.markdown-wrapper a')
    links.forEach(link => {
      link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noopener noreferrer')
    })
  }, [])

  useEffect(() => {
    const regex = /\[([^\]]+)\]\((https:\/\/cloud\.sellagen\.com\/[^)]+)\)/g
    const links = []
    let match
    
    while ((match = regex.exec(content)) !== null) {
      links.push({
        label: match[1],
        href: match[2]
      })
    }
    
    setFileLinks(links)
  }, [content])

  const handleCopy = () => {
    if(!isCopied) {
      navigator.clipboard.writeText(content).then(() => {
        setIsCopied(true)
        setTimeout(() => {
          if(setIsCopied) setIsCopied(false)
        }, 2000)
      }).catch(err => {
        console.log('Error copying to clipboard')
      })
    }
  }

  return (
    <div className='sndbx-convo-base sndbx-convo-assistant'>
      <div className='convo-control'>
        {isCopied ? 
          <i className="far fa-check ctrl-btn"></i>
          :
          <i className="far fa-clipboard ctrl-btn"
            onClick={handleCopy}
          ></i>
        }
      </div>
      <Markdown 
        className='markdown-wrapper'
        remarkPlugins={[remarkGfm]}
      >
        {quotationCleaner(content)}
      </Markdown>
      { fileLinks.length > 0 && (
        <div className='markdown-wrapper'>
          { fileLinks.map((link, index) => (
            <Previewer 
              key={`viewer-${link.href}-${index}`}
              label={link.label}
              href={link.href}
            />
          ))}
        </div>
      )}
    </div>
  )
}