import './styles.scss'

const ProgressBar = ({ progress=0, progresColor=null, bgProgressColor=null, barWidth='4px', highlightColor=null}) => {
  let _progress = typeof progress === 'number' ? Math.max(Math.min(progress, 100), 1) : 1
  let extraStyles = {}
  if(!highlightColor) {
    extraStyles = {
      border: 'none'
    }
  } else {
    extraStyles = {
      borderColor: highlightColor
    }
  }
  return (
    <div className='sv3-smp-progress-bar' style={{
      height: barWidth, backgroundColor: bgProgressColor || 'rgba(255, 255, 255, 0.15)'}}
    >
      <div className={`progress${highlightColor ? ' highlight' : ''}`} style={{
        width: `${_progress}%`,
        backgroundColor: progresColor || 'rgb(200, 200, 200)',
        ...extraStyles
      }}>
      </div>
    </div>
  )
}

export default ProgressBar