import React, { useContext, useEffect } from 'react'
import SandBoxStroage from '../../../components/file-explorer/file-explorer-back'
import { RightPanel, RightPanelBasicTitle } from './panel-utils'
import SandBoxContext from '../../../contexts/SandBoxContext'
import { FlowBasic, FlowStackedBody, FlowTags } from '../../../components/flow-ui/flow-blocks'
import Spinner from '../../../components/spinner'
import { ProfileMenu } from '.'
import Agent from '../title'
import ProgressBar from '../../../components/simple-progress-bar/progress-bar'
import { formatBytes } from '../../../utilities/handyFunctions'

const FsMap = {
  rootFolderId: 'My Drive/',
  fileMap: {
    'My Drive/': {
      id: 'My Drive/',
      name: 'My Drive',
      isDir: true,
      childrenIds: [],
      childrenCount: 0
    }
  }
}

const StoragePanel = () => {
  const {syncStorage, sandboxState, sandboxSetState} = useContext(SandBoxContext)
  const {isStorageSyncing, isInitialStorageSync} = sandboxState

  useEffect(() => {
    if(isInitialStorageSync) {
      syncStorage()
    }
  }, [])

  return (
    <RightPanel>
      <ProfileMenu/>
      <RightPanelBasicTitle>
        <div className='sndbx-panel-close' onClick={() => { sandboxSetState({showSidePanel: false}) }}>
          <i className="far fa-chevron-right"></i>
        </div>
        <i className="fal fa-hdd" style={{color: 'rgb(29, 191, 113)'}}></i>&nbsp;&nbsp;Storage Panel
      </RightPanelBasicTitle>
      <div style={{width: '100%', overflowY: 'scroll', padding: '10px'}}>
        <FlowBasic
          title={<div style={{display: 'flex', alignItems: 'center'}}>
            File Explorer&nbsp;&nbsp;&nbsp;
            <span style={{backgroundColor: 'rgba(255,255, 255, 0.1)', borderRadius: '5px', padding: '2px 5px', cursor: 'pointer'}}
              onClick={() => { syncStorage() }}
            >
              <i className="far fa-sync-alt"></i>
            </span>&nbsp;&nbsp;&nbsp;
            { !!isStorageSyncing && <Spinner dark={true} size={15}/> }
          </div>}
          titleIcon={<i className="fal fa-folders"></i>}
          border={'2px solid rgba(255, 255, 255, 0.2)'}
        >
          { !!!isInitialStorageSync &&
            <div style={{marginTop: '10px', backgroundColor: 'rgba(255,255, 255, 0.05)', borderRadius: '5px', lineHeight: 1.3}}>
              <SandBoxStroage/>
            </div>
          }
        </FlowBasic>
        <br/>
        <StorageUsagePanel/>
      </div>
    </RightPanel>
  )
}

const StorageUsagePanel = () => {
  const {syncStorageUsage, sandboxState} = useContext(SandBoxContext)
  const {isStorageUsageSyncing, isInitialStorageSync} = sandboxState
  useEffect(() => {
    if(isInitialStorageSync) {
      syncStorageUsage()
    }
  }, [])
  let storageUsageBlock = null
  if(!isStorageUsageSyncing) {
    const {size=null, maxAllocation=null} = sandboxState?.storageUsageData
    const formattedSize = formatBytes(size)
    const formattedMaxAllocation = formatBytes(maxAllocation)
    const percentUsed = ((size/maxAllocation)*100).toFixed(1)
    if(typeof size === 'number' && typeof maxAllocation === 'number') {
      storageUsageBlock = (
        <div className='sv2-flow-stacked-body' style={{height: 'auto', alignItems: 'unset'}}>
          <div className='title-icon'>
            <div className='square'
              style={{bottom: '12px'}}
            ></div>
          </div>
          <div style={{marginTop: '4px', width: '100%', maxWidth: '90%'}}>
            <div style={{width: '100%', backgroundColor: 'rgba(255,255, 255, 0.05)', borderRadius: '5px', padding: '10px', lineHeight: 1.3, fontFamily: 'Roboto Mono'}}>
              Storage provides a shared disk where you and Nelima can collaborate on tasks.
            </div>
            <FlowStackedBody showStep={false}>
              <div style={{width: '100%', display: 'inline-flex', alignItems: 'center', marginTop: '12.5px'}}>
                <div style={{flexGrow: 1}}>
                  Storage Utilization
                </div>
              </div>
            </FlowStackedBody>
            <br/>
            <div className='sndbx-context-blocks' style={{cursor: 'default'}}>
              <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                <div style={{
                  width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center',
                  backgroundColor: `rgba(116, 163, 251, 0.15)`, borderRadius: '5px',
                  color: `rgb(116, 163, 251)`, fontSize: '15px'
                }}>
                  <i className='far fa-hdd'></i>
                </div>&nbsp;
                <div style={{lineHeight: 1.4}}>
                  <span style={{color: `rgb(116, 163, 251)`}}>
                    Cloud Storage
                  </span><br/>
                  <span style={{fontSize: '10px', fontWeight: 400}}>
                    Directory <i class="fas fa-caret-right"></i> My Drive
                  </span>
                </div>
              </div>
              <br/>
              <ProgressBar
                progress={percentUsed}
                bgProgressColor='rgba(116, 163, 251, 0.3)'
                progresColor='rgb(116, 163, 251)'
                highlightColor='rgb(255, 255, 255)'
              />
              <div style={{marginTop: '7.5px'}}>
                {formattedSize} of {formattedMaxAllocation} Used ({percentUsed}% Full)
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      storageUsageBlock = (
        <div className='sndbx-context-blocks' style={{
          height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center',
          opacity: 0.7, marginTop: '10px'
        }}>
          Unable to fetch Storage Data
        </div>
      )
    }
  }
  return (
    <FlowBasic
      title={
        <div style={{display: 'flex', alignItems: 'center'}}>
          Storage Details
          &nbsp;&nbsp;&nbsp;
          {/* <span style={{backgroundColor: 'rgba(255,255, 255, 0.1)', borderRadius: '5px', padding: '2px 5px', cursor: 'pointer'}}
            onClick={() => { syncStorageUsage() }}
          >
            <i className="far fa-sync-alt"></i>
          </span>&nbsp;&nbsp;&nbsp; */}
          { !!isStorageUsageSyncing && <Spinner dark={true} size={15}/> }
        </div>
      }
      border={'2px solid rgba(255, 255, 255, 0.2)'}
      titleIcon={<i className="fal fa-database"></i>}
    >
      {storageUsageBlock}
    </FlowBasic>
  )
}

export default StoragePanel