import React, { useEffect, useMemo } from 'react'
import { useCache } from '../../contexts/CacheContext'
import axios from 'axios'
import { AutoScaleIframeV2, AutoScaleIframeV3 } from './AutoScaleIFrame'
import LoadingBars from '../spinner/horizontalLoader'
import { useEffectInView } from '../../utilities/hooks'
import './styles.scss'

const _HTMLViewer = ({ url }) => {
  const { fetchData, getCacheState } = useCache()
  const { isLoading, data, error } = getCacheState(url)

  useEffect(() => {
    fetchData(url, async (finalUrl) => {
      try {
        let response = await axios.get(finalUrl)
        // return DOMPurify.sanitize(response?.data || '')
        return response?.data || ''
      } catch(err) {
        console.error(err.error)
        throw err?.error || 'Error fetching html from the url'
      }
    })
  }, [url, fetchData])

  const memoizedSrcDoc = useMemo(() => data || '', [data])
  
  if(!data) return null
  return (
    <>
      <div className='sv3-prvw-title'>
        <h2>
          File Preview { isLoading && <>&nbsp;&nbsp;<LoadingBars/></> }
        </h2>
      </div>
      {/* <AutoScaleIframeV2 srcDoc={data} maxWidth={'100%'} maxHeight={1200}/> */}
      <AutoScaleIframeV3 srcDoc={memoizedSrcDoc} maxWidth={'100%'} maxHeight={2400}/>
    </>
  )
}

const HTMLViewer = ({ url }) => {
  const { fetchData, getCacheState } = useCache()
  const { isLoading, data, error } = getCacheState(url)

  const { ref } = useEffectInView(() => {
    fetchData(url, async (finalUrl) => {
      try {
        let response = await axios.get(finalUrl)
        return response?.data || ''
      } catch(err) {
        console.error(err.error)
        throw err?.error || 'Error fetching html from the url'
      }
    })
  }, [url, fetchData])

  const memoizedSrcDoc = useMemo(() => data || '', [data])
  
  if(!data) return <div ref={ref} />

  return (
    <div ref={ref}>
      <div className='sv3-prvw-title'>
        <h2>
          File Preview { isLoading && <>&nbsp;&nbsp;<LoadingBars/></> }
        </h2>
      </div>
      <AutoScaleIframeV3 srcDoc={memoizedSrcDoc} maxWidth={'100%'} maxHeight={2400}/>
    </div>
  )
}

export default HTMLViewer